<template>
  <div>
    <rule @result="modifyRule" text="Modificar" ref="viewRule" :key="keyFormRule"></rule>
  </div>
</template>
<script>
import Rule from './Rule'
import TicketManagementService from '../../ticketManagement.service.js'
export default {
  components: {Rule},
  data () {
    return {
      keyFormRule: 1,
      ticketManagementService: new TicketManagementService(this)
    }
  },
  methods: {
    modifyRule (queryParams) {
      this.$refs.viewRule.loading = true
      this.ticketManagementService.callService('updateRule', queryParams, {id: this.$route.params.id})
        .then(response => {
          this.$success('La regla ha sido modificada correctamente')
          // this.$router.push({name: 'ticket-rules'})
          location.reload()
        })
        .catch(err => {
          const text = 'Ocurrió un problema al modificar la regla'
          let textMessage = ''
          if (Array.isArray(err)) {
            textMessage += '<ul style="list-style-type: disc;">'
            err.forEach(element => {
              textMessage += `<li>${element.message}</li>`
            })
            textMessage += '</ul>'
          }
          this.$alert(textMessage ? textMessage : text)
        })
        .finally(end => {
          if (this.$refs.viewRule.loading) this.$refs.viewRule.loading = false
        })
    }
  }
}
</script>
<style>
  
</style>